import { performanceUrls } from "../config/performanceUrls";
import { Answer, Answers } from "../domain/entities/Answer";
import {
  EmployeeEvaluators,
  EvaluationEmployee,
} from "../domain/entities/EvaluationEmployee";
import { FeedbackRound } from "../domain/entities/FeedbackRound";
import { ProjectPeers } from "../domain/entities/Project";
import { Question, QuestionsAllcategory } from "../domain/entities/Question";
import ApiHelper from "../infrastructure/implementation/apiHelper";
import {
  InputType,
  dataToCamelCase,
  dataToSnakeCase,
} from "../infrastructure/implementation/helpers";
import { Evaluation } from "../domain/entities/Evaluation";

const api = new ApiHelper();
const resolveEvaluationsUrl = (path: string) => {
  return `${performanceUrls.evaluations}${path}`;
};
const resolveEmployeeUrl = (path: string) => {
  return `${performanceUrls.user}${path}`;
};

const getCatalogEmployees = async (): Promise<EvaluationEmployee[]> => {
  const response = await api.request("get", resolveEvaluationsUrl("/peers"));
  const body = response.body as any[];
  const catalogPeopleEmployees: EvaluationEmployee[] = body.map(
    (peopleEmployee: any) => ({
      externalId: peopleEmployee.external_id,
      name: peopleEmployee.name,
    }),
  );
  return catalogPeopleEmployees;
};

const getEvaluationQuestions = async (
  feedbackRoundId: string,
): Promise<QuestionsAllcategory> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(`/questions?feedback_round_id=${feedbackRoundId}`),
  );
  const selfQuestions: Question[] = response.body.self_questions.map(
    (question: InputType) => dataToCamelCase(question),
  );
  const peerQuestions: Question[] = response.body.peer_questions.map(
    (question: InputType) => dataToCamelCase(question),
  );
  const evaluationQuestions: QuestionsAllcategory = {
    selfQuestions,
    peerQuestions,
  };
  return evaluationQuestions;
};

const getActiveFeedbackRound = async (
  status: string,
): Promise<FeedbackRound> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(`/active_feedback_round?status=${status}`),
  );
  const feedbackRound: FeedbackRound = dataToCamelCase(response.body) as any;
  return feedbackRound;
};

const getProjectPeers = async (
  employeeExternalId: string,
): Promise<ProjectPeers[]> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/project_peers?employee_external_id=${employeeExternalId}`,
    ),
  );
  const camelProjectPeers: ProjectPeers[] = response.body.map(
    (projectPeersResponse: any) => ({
      employee: {
        externalId: projectPeersResponse.employee.external_id,
        name: projectPeersResponse.employee.name,
      },
      projects: projectPeersResponse.projects,
    }),
  );
  return camelProjectPeers;
};

const getExternalEmployee = async (
  employeeMail: string,
): Promise<EvaluationEmployee> => {
  const response = await api.request(
    "get",
    resolveEmployeeUrl(`/external_id?employee_mail=${employeeMail}`),
  );
  const externalEmployee: EvaluationEmployee = {
    externalId: response.body.external_id,
    name: response.body.name,
  };
  return externalEmployee;
};

const getEvaluationsAnswers = async (
  receiverUserId: string,
  feedbackRoundId: string,
): Promise<Answers> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/answers?sender_user_id=${receiverUserId}&feedback_round_id=${feedbackRoundId}`,
    ),
  );
  const selfAnswers: Answer[] = response.body.self_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const peerAnswers: Answer[] = response.body.peer_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const evaluationAnswers: Answers = {
    peerAnswers,
    selfAnswers,
  };
  return evaluationAnswers;
};

const getReceivedAnswers = async (
  feedbackRoundId: string,
  receiverUserId: string,
): Promise<Answers> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/peer/answers?feedback_round_id=${feedbackRoundId}&receiver_user_id=${receiverUserId}`,
    ),
  );
  const selfAnswers: Answer[] = response.body.self_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const peerAnswers: Answer[] = response.body.peer_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const answers: Answers = {
    selfAnswers,
    peerAnswers,
  };
  return answers;
};

const postEvaluationsAnswers = async (
  answers: Answer[],
  feedbackRoundId: string,
): Promise<void> => {
  const newAnswers = answers.map((answer) => dataToSnakeCase(answer as any));
  await api.request(
    "post",
    resolveEvaluationsUrl(`/answer?feedback_round_id=${feedbackRoundId}`),
    newAnswers,
  );
};

const getAllFeedbackRounds = async (): Promise<FeedbackRound[]> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl("/feedback_rounds"),
  );
  if (response.body !== undefined) {
    const feedbackRounds = dataToCamelCase(response.body) as FeedbackRound[];
    return feedbackRounds;
  }
  throw new Error(response);
};

const getFeedbackRoundById = async (
  feedbackRoundId: string,
): Promise<FeedbackRound> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(`/feedback_round/id/${feedbackRoundId}`),
  );
  if (response.body !== undefined) {
    const feedbackRound = dataToCamelCase(
      response.body,
    ) as unknown as FeedbackRound;
    return feedbackRound;
  }
  throw new Error(response);
};

const createFeedBackRound = async (
  feedbackRound: FeedbackRound,
  isDraft: boolean,
) => {
  const payload: FeedbackRound = dataToSnakeCase(feedbackRound as any) as any;
  payload.questions = dataToCamelCase(payload.questions as any) as Question[];
  const response = await api.request(
    "post",
    resolveEvaluationsUrl(`/feedback_round?is_draft=${isDraft.toString()}`),
    payload,
  );
  if (response.statusCode !== undefined) return response;
  throw new Error(response);
};

const updateFeedbackRoundSharingStatus = async (
  feedbackRound: FeedbackRound,
) => {
  const updatedFeedbackRound = dataToSnakeCase({
    ...feedbackRound,
    questions: undefined,
  });
  const response = await api.request(
    "put",
    resolveEvaluationsUrl("/feedback_round"),
    updatedFeedbackRound,
  );
  if (response.statusCode !== undefined) return response.message;
  throw new Error(response);
};

const updateEvaluation = async (
  evaluation: Evaluation,
): Promise<Evaluation> => {
  const updatedEvaluation = dataToSnakeCase({ ...evaluation });
  const response = await api.request(
    "put",
    performanceUrls.evaluations,
    updatedEvaluation,
  );
  if (response.statusCode !== undefined) return response.message;
  throw new Error(response);
};

const updateEvaluationEditionRequest = async (
  evaluation: Evaluation,
): Promise<Evaluation> => {
  const updatedEvaluation = dataToSnakeCase({ ...evaluation });
  const response = await api.request(
    "put",
    resolveEvaluationsUrl("/edition_request"),
    updatedEvaluation,
  );
  if (response.statusCode !== undefined) return response.message;
  throw new Error(response);
};

const getEvaluation = async (
  feedbackRoundId: string,
  userExternalId: string,
): Promise<Evaluation> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/feedback_round?feedback_round_id=${feedbackRoundId}&user_external_id=${userExternalId}`,
    ),
  );
  const evaluationStatus = dataToCamelCase(
    response.body,
  ) as unknown as Evaluation;
  return evaluationStatus;
};

const needsAnswersToFillOut = async (
  feedbackRoundId: string,
  userExternalId: string,
  totalAnswersToFillOut: number,
): Promise<boolean> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/check_answers` +
        `?feedback_round_id=${feedbackRoundId}` +
        `&user_external_id=${userExternalId}` +
        `&total_answers_to_fill_out=${totalAnswersToFillOut}`,
    ),
  );
  return response.body;
};

const getSharedFeedbackRounds = async (): Promise<FeedbackRound[]> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl("/shared/feedback_rounds"),
  );
  const sharedFeedbackRounds: FeedbackRound[] = response.body.map(
    (sharedFeedbackRound: InputType) => dataToCamelCase(sharedFeedbackRound),
  );
  return sharedFeedbackRounds;
};

const getEvaluatorsFromFeedbackRound = async (
  feedbackRoundId: string,
): Promise<EvaluationEmployee[]> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(`/feedback_round/id/${feedbackRoundId}/evaluators`),
  );
  if (response.body !== undefined) {
    const evaluators = dataToCamelCase(response.body) as EvaluationEmployee[];
    return evaluators;
  }
  throw new Error(response);
};

const getEvaluatorsOfSelectedEmployee = async (
  feedbackRoundId: string,
  employeeId: string,
): Promise<EmployeeEvaluators> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/feedback_round/id/${feedbackRoundId}/evaluators/for/${employeeId}`,
    ),
  );
  if (response.body !== undefined) {
    const giverEvaluators = dataToCamelCase(
      response.body.giver_evaluators,
    ) as EvaluationEmployee[];
    const receiverEvaluators = dataToCamelCase(
      response.body.receiver_evaluators,
    ) as EvaluationEmployee[];
    return {
      giverEvaluators,
      receiverEvaluators,
    };
  }
  throw new Error(response);
};

const getEvaluationReviewStatus = async (
  feedbackRoundId: string,
  userExternalId: string,
): Promise<boolean> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/feedback_round/id/${feedbackRoundId}/evaluation/review_status?selected_employee_id=${userExternalId}`,
    ),
  );
  const evaluationReviewStatus: boolean = response.body;
  return evaluationReviewStatus;
};

const updateEvaluationReviewStatus = async (
  feedbackRoundId: string,
  userExternalId: string,
  newReviewStatus: boolean,
): Promise<Evaluation> => {
  const response = await api.request(
    "put",
    resolveEvaluationsUrl(
      `/feedback_round/id/${feedbackRoundId}/evaluation/review_status?selected_employee_id=${userExternalId}&new_review_status=${String(
        newReviewStatus,
      )}`,
    ),
  );
  if (response.body !== undefined) {
    const evaluationItem = dataToCamelCase(
      response.body,
    ) as unknown as Evaluation;
    const evaluation: Evaluation = {
      ...evaluationItem,
      feedbackRoundId,
    };
    return evaluation;
  }
  throw new Error(response);
};

const putEditedAnswers = async (
  answers: Answer[],
  feedbackRoundId: string,
): Promise<void> => {
  const newAnswers = answers.map((answer) => dataToSnakeCase(answer as any));
  await api.request(
    "put",
    resolveEvaluationsUrl(`/answer?feedback_round_id=${feedbackRoundId}`),
    newAnswers,
  );
};

const getEditedAnswers = async (
  feedbackRoundId: string,
  receiverUsersId: string[],
): Promise<Answer[]> => {
  const response = await api.request(
    "post",
    resolveEvaluationsUrl(
      `/edited_answers?feedback_round_id=${feedbackRoundId}`,
    ),
    receiverUsersId,
  );
  const peerAnswers: Answer[] = response.body.map((answer: InputType) =>
    dataToCamelCase(answer),
  );
  return peerAnswers;
};

const deleteDraft = async (feedbackRoundId: string): Promise<string> => {
  const response = await api.request(
    "delete",
    resolveEvaluationsUrl(`/draft?feedback_round_id=${feedbackRoundId}`),
  );
  return response.message;
};

const deleteDraftQuestions = async (
  feedbackRoundId: string,
  questionId: string,
  type: string,
): Promise<string> => {
  const response = await api.request(
    "delete",
    resolveEvaluationsUrl(
      `/draft_questions?feedback_round_id=${feedbackRoundId}&question_id=${questionId}&type=${type}`,
    ),
  );
  if (response.statusCode === 200) {
    return response.message;
  }
  throw new Error(response);
};

const getAnswersAndEditedAnswers = async (
  feedbackRoundId: string,
  receiverUserId: string,
): Promise<Answers> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/received_answers?feedback_round_id=${feedbackRoundId}&receiver_user_id=${receiverUserId}`,
    ),
  );
  const selfAnswers: Answer[] = response.body.self_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const peerAnswers: Answer[] = response.body.peer_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const answers: Answers = {
    selfAnswers,
    peerAnswers,
  };
  return answers;
};

const getMyFeedbackRoundAnswers = async (
  feedbackRoundId: string,
): Promise<Answers> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/received_answers/feedback_round/me?feedback_round_id=${feedbackRoundId}`,
    ),
  );
  const selfAnswers: Answer[] = response.body.self_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const peerAnswers: Answer[] = response.body.peer_answers.map(
    (answer: InputType) => dataToCamelCase(answer),
  );
  const answers: Answers = {
    selfAnswers,
    peerAnswers,
  };
  return answers;
};

const getEvaluationsRequests = async (
  feedbackRoundId: string,
): Promise<Evaluation[]> => {
  const response = await api.request(
    "get",
    resolveEvaluationsUrl(
      `/edition_requests?feedback_round_id=${feedbackRoundId}`,
    ),
  );
  if (response.body !== undefined) {
    const evaluations = dataToCamelCase(response.body) as Evaluation[];
    return evaluations;
  }
  throw new Error(response);
};

const updateEvaluationsEditionRequests = async (
  evaluations: Evaluation[],
): Promise<Evaluation> => {
  const updatedEvaluations = evaluations.map((evaluation) =>
    dataToSnakeCase(evaluation as any),
  );
  const response = await api.request(
    "put",
    resolveEvaluationsUrl(`/approved`),
    updatedEvaluations,
  );
  if (response.statusCode !== undefined) return response.message;
  throw new Error(response);
};

export const evaluationServices = {
  getExternalEmployee,
  getCatalogEmployees,
  getEvaluationQuestions,
  getProjectPeers,
  getEvaluationsAnswers,
  getReceivedAnswers,
  postEvaluationsAnswers,
  getAllFeedbackRounds,
  getActiveFeedbackRound,
  createFeedBackRound,
  updateEvaluation,
  getEvaluation,
  updateFeedbackRoundSharingStatus,
  needsAnswersToFillOut,
  getSharedFeedbackRounds,
  getFeedbackRoundById,
  getEvaluatorsFromFeedbackRound,
  getEvaluatorsOfSelectedEmployee,
  getEvaluationReviewStatus,
  updateEvaluationReviewStatus,
  putEditedAnswers,
  getEditedAnswers,
  deleteDraft,
  deleteDraftQuestions,
  getAnswersAndEditedAnswers,
  getMyFeedbackRoundAnswers,
  getEvaluationsRequests,
  updateEvaluationsEditionRequests,
  updateEvaluationEditionRequest,
};
